<template>
  <div>

    <h3 class="subtitle-1">Tutorials</h3>
    <ul class="video-list ml-3">
      <li>
        <a href="https://youtu.be/ZjdHSQ4cUZ0" target="_blank">
          <v-icon small>video_library</v-icon> Kurzeinführung <span class="runtime">(6 Min.)</span>
        </a>
      </li>
      <li>
        <a href="https://youtu.be/mRwylm5PIAI" target="_blank">
          <v-icon small>video_library</v-icon> Ihr Informationstool <span class="runtime">(9 Min.)</span>
        </a>
      </li>
      <li>
        <a href="https://youtu.be/8eJCMuqs2mY" target="_blank">
          <v-icon small>video_library</v-icon> Für Ihre Reisenden <span class="runtime">(7 Min.)</span>
        </a>
      </li>
    </ul>

    <h3 class="subtitle-1 mt-6">Hilfeseiten</h3>
    <ul class="video-list ml-3">
      <li><a href="https://www.global-monitoring.com/blog/portfolio/faq-destination-manager/" target="_blank">FAQ</a></li>
      <li><a href="https://www.global-monitoring.com/blog/portfolio/destination-manager-handbuch/" target="_blank">Handbuch</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TutorialsAndHelp"
}
</script>

<style scoped>
.video-list a {
  text-decoration: none;
}

.runtime {
  font-style: italic;
  font-size: 90%;
}
</style>