import { render, staticRenderFns } from "./StatusAlert.vue?vue&type=template&id=b8bc2000&scoped=true"
import script from "./StatusAlert.vue?vue&type=script&lang=js"
export * from "./StatusAlert.vue?vue&type=script&lang=js"
import style0 from "./StatusAlert.vue?vue&type=style&index=0&id=b8bc2000&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8bc2000",
  null
  
)

export default component.exports