<template>
  <div class="status-outer" :class="{'is-small': small}">
    <span
      class="status"
      v-bind:class="cssClass"
    >
      {{ description }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'StatusAlert',
  props: {
    status: {
      type: String,
      default: "WARNING"
    },
    authorityType: {
      type: String,
      default: "DESTCOUNTRY"
    },
    small: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    description() {
      let status = this.status
      if (this.authorityType == 'FOREIGNOFFICE' && status == 'NOTPOSSIBLE') {
        status = 'WARNING'
      }

      let description = "Unbekannt"
      switch (status) {
        case "NOTPOSSIBLE": description = this.$i18n.t('main.NOTPOSSIBLE_STATUS'); break
        case "NOACCESS": description = this.$i18n.t('main.NOTPOSSIBLE_STATUS'); break
        case "WARNING": description = this.$i18n.t('main.WARNING_STATUS'); break
        case "RESTRICTIONS": description = this.$i18n.t('main.RESTRICTIONS_STATUS'); break
        case "POSSIBLE": description = this.$i18n.t('main.POSSIBLE_STATUS'); break
        case "CONFLICTING": description = this.$i18n.t('main.CONFLICTING_STATUS'); break
        case "NOTINPORTFOLIO": description = this.$i18n.t('main.NOTPOSSIBLE_STATUS'); break
        case "NOTFOUND": description = this.$i18n.t('main.NOTPOSSIBLE_STATUS'); break
        case "UNKNOWN": description = this.$i18n.t('main.UNKNOWN_STATUS'); break
      }
      return description
    },
    cssClass() {
      return 'status-' + this.status.toLowerCase()
    }
  },
}
</script>

<style scoped>
  .status-outer {
    margin: 0.5rem 0;
  }

  .status {
    padding: 0.4rem 1rem;
    font-weight: bold;
    border-radius: 0.3rem;
    text-transform: uppercase;
  }

  .status-warning, .status-notpossible, .status-noaccess {
    background-color: #ca0c70;
    color: white;
  }

  .status-restrictions {
    background-color: #c59211;
    color: white;
  }

  .status-possible {
    background-color:blue;
    color: white;
  }

  .status-unknown {
    background-color:gray;
    color: white;
  }

  .is-small {
    font-size: 80%;
    margin: 0.1rem 0;
  }

  .is-small .status {
    padding: 0.2rem 0.5rem;
  }

</style>