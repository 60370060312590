<template>
  <div class="index-map-view">
    <div class="index-titles">
      <div class="footer"></div>
      <div class="title title-1" :class="{ of3: !has4Panels, of4: has4Panels }">{{ $t("main.ABOUT_DESTINATIONMANAGER") }}</div>
      <div class="title title-2 important-news" :class="{ of3: !has4Panels, of4: has4Panels }">{{ $t("main.IMPORTANT_NEWS") }}</div>
      <div class="title title-3 ticker" :class="{ of3: !has4Panels, of4: has4Panels }">
        {{ $t("main.TICKER") }}
        <v-btn text color="white" style="margin-left: 10%" small class="mt-1" :to="{name: 'organisation-countries-selection'}">
          <v-badge v-if="countryIds.length" color="green" :content="countryIds.length"><v-icon class="mr-2" small>edit</v-icon>  {{ $t("main.FAVORITES") }} </v-badge>
          <div v-else>
            <v-icon class="mr-2" small>edit</v-icon> {{ $t("main.FAVORITES") }}
          </div>
        </v-btn>
      </div>
      <div class="title title-4 events">
        {{ $t("main.ALL_GMEVENTS") }}
        <div class="live-events" v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings')">
          <v-switch color="#ffffff" @change="toggleLive" inset></v-switch>
          <v-badge color="red" :content="amountLiveEvents" v-if="amountLiveEvents">
            <span class="title title-4" :style="{width: '100%', padding: '0 !important', color: '#f44336'}">{{ $t("main.LIVE_EVENTS") }}</span>
          </v-badge>
          <span class="title title-4" :style="{ width: '100%', padding: '0 !important'}" v-else>{{ $t("main.LIVE_EVENTS") }}</span>
        </div>
      </div>
    </div>

    <div class="index-panels">
      <div class="panel panel-1" :class="{ of3: !has4Panels, of4: has4Panels }">
        <v-container
          v-if="$i18n.locale == 'de'"
          class="pb-2 pl-7 mt-2"
        >
          <TutorialsAndHelp />
        </v-container>

        <v-container class="mt-4">
          <div class="news-subheader mb-2">{{ $t('main.NEWS') }}</div>
          <div class="news-list">

            <NewsList
              :news="destinationManagerNews"
              :loading="isLoading"
            />
          </div>
        </v-container>

        <!--
        <v-spacer />
        -->


      </div>

      <div class="panel panel-2" :class="{ of3: !has4Panels, of4: has4Panels }">
        <v-container height="100%">
          <div class="news-subheader"></div>

          <div class="news-list">
            <div>
              <NewsList
                :news="breakingNews"
                :loading="isLoading"
              />
            </div>
            <div v-if="breakingNews.length == 0 && !isLoading">
              <p class="font-italic">{{ $t("main.NO_CURRENT_NEWS") }}</p>
            </div>
          </div>

          <div class="old-news">
            <h1 class="subtitle-1">{{ $t("main.OLDER_NEWS") }}</h1>
            <div class="news-list">
              <NewsList
                :news="oldBreakingNews"
                :loading="isLoading"
              />
            </div>
          </div>

        </v-container>
      </div>

      <div class="panel panel-3" :class="{ of3: !has4Panels, of4: has4Panels }">
        <v-container height="100%">
          <div class="news-subheader"></div>
          <div class="news-list">
            <NewsList
              :news="ticker"
              :loading="isLoading"
            />
          </div>
        </v-container>
      </div>

      <div
        v-if="$hasPermission('a3m_core.DestinationManagerEvents')"
        class="panel panel-4a"
        :class="{ of3: !has4Panels, of4: has4Panels, 'with-pinned': pinnedEventsOpen }"
      >
        <v-container height="100%">
          <div class="news-subheader">
            <span  class="powered-by">
              Powered by <a href="https://global-monitoring.com/"  target="_blank">Global Monitoring</a>
            </span>
          </div>
          <div class="event-list">
            <div class="news-list">
              <EventList
                :events="eventsWithIsUnread"
                :eventsLive="eventsWithIsUnreadLive"
                :countryIds="countryIds"
                :loading="isLoading"
                :liveEvents="liveEvents"
              />
            </div>
          </div>
        </v-container>
      </div>

      <div
        v-if="$hasPermission('a3m_core.DestinationManagerEvents')"
        class="panel panel-4b"
        :class="{ of3: !has4Panels, of4: has4Panels, 'with-pinned': pinnedEventsOpen, 'closed': !pinnedEventsOpen }"
      >
        <div class="pinned-slider-title" @click="togglePinnedEvents">
          <v-icon v-if="!pinnedEventsOpen">arrow_drop_up</v-icon>
          <v-icon v-if="pinnedEventsOpen">arrow_drop_down</v-icon>
          <span class="pinned-slider-text">{{ $t("main.PINNED_GMEVENTS") }}</span>
        </div>
        <div class="pinned-content">
          <EventList
            :events="eventsPinnedWithIsUnread"
            :countryIds="countryIds"
            :pinned="true"
            :loading="isLoading"
          />
        </div>
      </div>

    </div>

    <v-btn
      fab
      fixed
      bottom
      right
      style="z-index: 11"
      color="primary"
      @click="reloadNews"
      :loading="isLoading"
    >
      <v-icon>refresh</v-icon>
    </v-btn>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
//import EntryRestrictionChangeLog from '@/components/entryrestriction/EntryRestrictionChangeLog';
import EventList from '@/components/event/EventList';
import NewsList from '@/components/news/NewsList';
import TutorialsAndHelp from '@/components/help/TutorialsAndHelp';

export default {
  name: 'IndexView',
  components: {
    //EntryRestrictionChangeLog,
    EventList,
    NewsList,
    TutorialsAndHelp
  },
  data() {
    return {
      isLoading: false,
      pinnedEventsOpen: false,
      amountLiveEvents: 0,
      liveEvents: false,
    }
  },
  mounted() {
    this.reloadNews()
    this.countLiveEvents()

    this.pinnedEventsOpen = this.openPinnedEvents
  },
  computed: {
    ...mapState({
      user: state => state.authStore.user,
      language: state => state.uiStore.language,
      openPinnedEvents: state => state.uiStore.openPinnedEvents
    }),
    ...mapGetters({
      breakingNews: 'newsStore/breakingNews',
      oldBreakingNews: 'newsStore/oldBreakingNews',
      ticker: 'tickerStore/withIsUnread',
      destinationManagerNews: 'newsStore/destinationManagerNews',
      eventsWithIsUnread: 'eventsLatestSignificantUpdatesStore/withIsUnread',
      eventsWithIsUnreadLive: 'eventsLatestSignificantUpdatesStore/withIsUnreadLive',
      eventsPinnedWithIsUnread: 'eventsLatestSignificantUpdatesPinnedStore/withIsUnread',
      countryIds: 'organisationCountrySelectionsStore/countryIds'
    }),
    cdbAccessKey() {
      return this.user ? this.user.cdbAccessKey : ''
    },
    has4Panels() {
      return this.$hasPermission('a3m_core.DestinationManagerEvents')
    },
  },
  methods: {
    ...mapActions({
      setLastNewsVisitDateToNow: 'uiStore/setLastNewsVisitDateToNow',
      fetchNewsAndLatestUpdates: 'uiStore/fetchNewsAndLatestUpdates',
      eventsFetchAll: 'eventsLatestSignificantUpdatesStore/fetchAll',
    }),
    ...mapMutations({
      SET_OPEN_PINNED_EVENTS: 'uiStore/SET_OPEN_PINNED_EVENTS'
    }),
    doFetchNewsAndLatestUpdates() {
      this.isLoading = true

      let eventPromise = this.eventsFetchAll({ force: true })
      let newsPromise = this.fetchNewsAndLatestUpdates()

      Promise.all([eventPromise, newsPromise]).then(() => {
        // we wait two seconds before we mark the page as visited, to that all
        // tasks are definitly run
        setTimeout(() => {
          this.setLastNewsVisitDateToNow()
        }, 2000)
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    reloadNews() {
      this.doFetchNewsAndLatestUpdates()
    },
    togglePinnedEvents() {
      this.pinnedEventsOpen = !this.pinnedEventsOpen
      this.SET_OPEN_PINNED_EVENTS(this.pinnedEventsOpen)
    },
    toggleLive() {
      this.liveEvents = !this.liveEvents;
    },
    countLiveEvents(){
      this.amountLiveEvents = this.eventsWithIsUnreadLive.length
    }
  },
  watch: {
    eventsWithIsUnread: {
      handler() {
        this.countLiveEvents();
      },
      immediate: true,
      deep: true
    }
  },
}
</script>

<style scoped>

  .index-map-view {
    margin: auto;
  }

  .index-header {
    position: absolute;
    text-align: center;
    top: 0px;
    height: 150px;
    left: 0;
    right: 0;
    padding-top: 1rem;
  }

  .index-header .country-selections {
    position: absolute;
    right: 1rem;
    top: 2rem;
  }

  .ticker{
    width: 22% !important;
    left: 45.5%;
    position: absolute;
  }

  .events{
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    left: 70%;
    position: absolute;
    width: 27% !important;
  }

  .important-news{
    left: 22%;
    position: absolute;
  }

  .live-events{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .index-panels {
    position: absolute;
    top: 60px;
    bottom: 0px;
    left: 0;
    right: 0;
  }

  .panel {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: auto;
    scrollbar-width: thin;
    padding: 0 2rem 0 1rem;;
  }

  .panel.closed {
    overflow: hidden;
  }

  .panel.of3 {
    width: 33%;
  }
  .panel.of4 {
    width: 23%;
  }

  .panel ::-webkit-scrollbar {
    width: 10px;
  }

  .panel-1 {
    left: 0%;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .panel-2.of3 {
    left: 33%;
  }
  .panel-2.of4 {
    left: 22%;
  }

  .panel-3.of3 {
    left: 66%;
    width: auto;
    right: 0;
  }
  .panel-3.of4 {
    left: 45%;
  }

  .panel-4a {
    left: 69%;
    width: 32% !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0;
    bottom: 5%;
  }

  .panel-4a.with-pinned {
    bottom: 48%;
  }

  .panel-4b {
    background-color: #ffffff;
    left: 69%;
    top: auto;
    height: 50px;
    bottom: 0px;
    width: 32% !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .panel-4b.with-pinned {
    height: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .panel h1 {
    font-size: 18px;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .index-titles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    height: 60px;
    padding-top: 24px;

    background: url("../assets/network.svg");
    background-color: #003b71;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .index-titles .title {
    width: 25%;
    height: 100%;
    float: left;
    padding-left: 2rem;
  }

  .index-titles .title.of3 {
    width: 33%;
  }

  .news-list {
    margin: 0.5rem 1rem;
  }

  .old-news {
    margin-top: 2rem;
  }

  .index-header .a3m-logo {
    /*width: 420px;*/
    height: 45px;
    vertical-align: middle;
    margin-top: -18px;
    padding-right: 1rem;
  }

  .index-header .siteName {
    font-size: 34px;
    font-family:Roboto, sans-serif;
    color: #6f6f6f;
    font-weight: 300;
    line-height: 52px;
  }

  .news-subheader {
    height: 24px;
    margin: -0.6rem 1rem -1rem 1rem;
  }

  .news-subheader .powered-by {
    float: right;
    font-size: 90%;
    color: gray;
  }

  .pinned-slider-title {
    position: sticky;
    top: 0;
    z-index: 10;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid #cecece;
    cursor: pointer;
    /*background-color: #f1f1f1;*/
    background-color: #e8f1fa;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0.5rem;
    line-height: 32px;
    margin: 0 -2rem;
  }

  .pinned-slider-text {
    /*
    color: #1867c0;
    border-bottom: 2px solid #1867c0;
    */
    color: #1976d2 !important;
    caret-color: #1976d2 !important;
    font-family:Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .pinned-content {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }

</style>

<style>
  .index-map-view .buttonGrid .v-btn .v-btn__content {
    padding: 2rem;
  }

  .index-map-view .buttonGrid .v-btn.primary {
    background-color: #1976d2d9 !important;
  }

  .theme--light.v-input--switch .v-input--switch__track {
    color: #807f7f;
  }

</style>
