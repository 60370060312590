<template>
  <v-dialog v-model="showDialog" max-width="600" @click:outside="close">
    <v-card v-if="news">
      <v-card-title class="headline">
        {{ news.title }}
      </v-card-title>

      <v-card-text>
        <p>{{ $t("main.DATE") }}: {{ news.createdAt|i18n_datetimeToGerman }}</p>
        <div v-html="news.text"></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close">{{ $t("main.CLOSE_ACTION") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewsDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    news: {
      type: Object
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  watch: {
    show() {
      this.showDialog = this.show
    }
  }

}
</script>