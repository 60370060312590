var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('main.LEGEND')))]),_c('table',{staticClass:"legend-table"},[_c('tr',[_vm._m(0),_c('td',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t("main.POSSIBLE_STATUS")))])]),_c('tr',[_vm._m(1),_c('td',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t("main.WARNING_STATUS")))])]),_c('tr',[_vm._m(2),_c('td',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t("main.NOTPOSSIBLE_STATUS")))])]),_c('tr',[_vm._m(3),_c('td',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t("main.RESTRICTIONS_STATUS")))])]),_c('tr',[_vm._m(4),_c('td',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t("main.UNKNOWN_STATUS")))])])]),_c('table',{staticClass:"legend-table mt-2"},[_c('tr',[_vm._m(5),_c('td',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t("main.WARNING_DEVIATION_STATUS")))])]),_c('tr',[_vm._m(6),_c('td',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t("main.NOTPOSSIBLE_DEVIATION_STATUS")))])]),_c('tr',[_vm._m(7),_c('td',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t("main.RESTRICTIONS_DEVIATION_STATUS")))])])]),_c('div',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.$t('main.LEGEND_SEVEN_DAY_INCIDENCE')))])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"legend legend-POSSIBLE"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"legend legend-WARNING"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"legend legend-NOTPOSSIBLE"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"legend legend-RESTRICTIONS"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"legend legend-UNKNOWN"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"legend legend-WARNING"},[_c('div',{staticClass:"legend-deviation"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"legend legend-NOTPOSSIBLE"},[_c('div',{staticClass:"legend-deviation"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"legend legend-RESTRICTIONS"},[_c('div',{staticClass:"legend-deviation"})])])
}]

export { render, staticRenderFns }