<template>
  <div>
    <div>{{ dateStart|i18n_dateLong }} - {{ dateEnd|i18n_dateLong }}</div>

    <h2 class="subtitle-1 mt-2">{{ $t("main.DEPARTURE_FROM_GERMANY") }}</h2>
    <StatusAlert :status="statusForeignOffice" authorityType="FOREIGNOFFICE" />
    {{ $t("main.ACCORDING_FOREIGN_OFFICE") }}

    <h2 class="subtitle-1 mt-2">{{ $t("main.ENTER_POSSIBILITY_DEST_COUNTRY") }}</h2>
    <StatusAlert :status="statusDestinationCountry" authorityType="DESTCOUNTRY"  />
    {{ $t("main.ENTER_FROM_GERMANY") }}
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment"
import StatusAlert from "@/components/ui/StatusAlert"

export default {
  name: 'DestinationWorldMapStatus',
  components: {
    StatusAlert
  },
  props: {
    statusForeignOffice: String,
    statusDestinationCountry: String,
    dateStart: String,
    dateEnd: String,
  },
}
</script>

<style scoped>
</style>