<template>
  <div>
    <b>{{ $t('main.LEGEND') }}</b>
    <table class="legend-table">
      <tr>
        <td><div class="legend legend-POSSIBLE" /></td>
        <td class="pl-1">{{ $t("main.POSSIBLE_STATUS") }}</td>
      </tr>
      <tr>
        <td><div class="legend legend-WARNING" /></td>
        <td class="pl-1">{{ $t("main.WARNING_STATUS") }}</td>
      </tr>
      <tr>
        <td><div class="legend legend-NOTPOSSIBLE" /></td>
        <td class="pl-1">{{ $t("main.NOTPOSSIBLE_STATUS") }}</td>
      </tr>
      <tr>
        <td><div class="legend legend-RESTRICTIONS"></div></td>
        <td class="pl-1">{{ $t("main.RESTRICTIONS_STATUS") }}</td>
      </tr>
      <tr>
        <td><div class="legend legend-UNKNOWN" /></td>
        <td class="pl-1">{{ $t("main.UNKNOWN_STATUS") }}</td>
      </tr>
    </table>

    <table class="legend-table mt-2">
      <tr>
        <td><div class="legend legend-WARNING"><div class="legend-deviation" /></div></td>
        <td class="pl-1">{{ $t("main.WARNING_DEVIATION_STATUS") }}</td>
      </tr>
      <tr>
        <td><div class="legend legend-NOTPOSSIBLE"><div class="legend-deviation" /></div></td>
        <td class="pl-1">{{ $t("main.NOTPOSSIBLE_DEVIATION_STATUS") }}</td>
      </tr>
      <tr>
        <td><div class="legend legend-RESTRICTIONS"><div class="legend-deviation" /></div></td>
        <td class="pl-1">{{ $t("main.RESTRICTIONS_DEVIATION_STATUS") }}</td>
      </tr>
    </table>

    <div class="mt-4">{{ $t('main.LEGEND_SEVEN_DAY_INCIDENCE') }}</div>
  </div>
</template>

<script>
export default {
  name: 'DestinationMapLegend',
}
</script>

<style scoped>
  .legend {
    height: 12px;
    width: 12px;
    border: 1px solid gray;
  }

  .legend-POSSIBLE {
    background-color: rgba(14, 119, 206, 0.56);
  }

  .legend-NOTPOSSIBLE {
    background-color: #F4433690;
  }

  .legend-RESTRICTIONS {
    background-color: #FFC10790;
  }

  .legend-UNKNOWN {
    background-color: #80808090;
  }

  .legend-WARNING {
    background-color: #00000090;
  }

  .legend-deviation {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    margin-top: 2px;
    height: 6px;
  }
</style>