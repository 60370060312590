<template>
  <div>
    <div v-if="countriesIsLoading">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-if="countriesIsDone">
      <h1 class="title">{{ countryByIso2.nameShort }}</h1>

      <div v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <div v-if="!loading">

        <DestinationWorldMapStatus
          :statusForeignOffice="countryStatus.statusForeignOffice"
          :statusDestinationCountry="countryStatus.statusDestinationCountry"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
        />

        <v-btn
          class="mt-4"
          color="primary"
          small
          :to="{name: 'destination-result', params: {
            departureId: germany.id,
            departureType: 'COUNTRY',
            destinationId: countryByIso2.id,
            destinationType: 'COUNTRY',
            citizenshipCountryId: germany.id,
            restrictionType: restrictionType,
            transitId: 0,
            transitType: 'UNDEFINED',
            dateStart: dateStart,
            dateEnd: dateEnd,
          }}"
        >
          {{ $t("main.SHOW_DETAILS") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DestinationWorldMapStatus from "./DestinationWorldMapStatus"

export default {
  name: 'DestinationWorldMapCountryDetail',
  components: {
    DestinationWorldMapStatus
  },
  props: {
    iso2: String,
    restrictionType: String,
    dateStart: String,
    dateEnd: String,
  },
  data() {
    return {
      loading: true,
      countryStatus: null
    }
  },
  mounted() {
    this.doCountriesFetchAll();
  },
  computed: {
    ...mapState({
      countries: state => state.countriesStore.entries,
      countriesIsDone: state => state.countriesStore.isDone,
      countriesIsLoading: state => state.countriesStore.isLoading,
    }),
    germany() {
      let country = this.countries.find(country => country.iso2 == "DE")
      return country
    },
    countryByIso2() {
      if (!this.countriesIsDone || !this.iso2) {
        return
      }

      let country = this.countries.find(country => country.iso2 == this.iso2)
      return country
    }
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll",
    }),

    // TODO MOVE TO SERVICE (how to use i18n in a service?)
    getTextByRkiStatus(rkiStatus) {
      let text = this.$i18n.t('main.UNKNOWN_STATUS')
      switch(rkiStatus) {
        case 'RISKFREEAREA': text = this.$i18n.t('main.RKI_RISKFREEAREA_STATUS'); break
        case 'RISKAREA': text = this.$i18n.t('main.RKI_RISKAREA_STATUS'); break
        case 'HIGHINCIDENCEAREA': text = this.$i18n.t('main.RKI_HIGHINCIDENCEAREA_STATUS'); break
        case 'VIRUSVARIANTAREA': text = this.$i18n.t('main.RKI_VIRUSVARIANTAREA_STATUS'); break
        case 'PARTIALLYRISKAREA': text = this.$i18n.t('main.RKI_PARTIALLYRISKAREA_STATUS'); break
        case 'PARTIALLYHIGHINCIDENCEAREA': text = this.$i18n.t('main.RKI_PARTIALLYHIGHINCIDENCEAREA_STATUS'); break
        case 'PARTIALLYVIRUSVARIANTAREA': text = this.$i18n.t('main.RKI_PARTIALLYVIRUSVARIANTAREA_STATUS'); break
        case 'ALLRISKAREA': text = this.$i18n.t('main.RKI_ALLRISKAREA_STATUS'); break
      }
      return text
    },
    doCountriesFetchAll() {
      this.countriesFetchAll().then(() => {
        this.getCountryStatus(this.countryByIso2.id)
      })
    },
    getCountryStatus(id) {
      this.loading = true
      this.$mapRepository.getCountryStatus(this.countryByIso2.id, this.restrictionType, this.dateStart, this.dateEnd)
        .then((result) => {
          this.countryStatus = result
          this.loading = false
        })
    },
  },
  watch: {
    iso2() {
      this.doCountriesFetchAll();
    },
    countryByIso2() {
      if (this.countryByIso2) {
        this.getCountryStatus(this.countryByIso2.id)
      }
    }
  },
}
</script>

<style scoped>
</style>