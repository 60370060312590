<template>
  <div>
    <div class="news-date-block">
      <DateList
        :items="news"
        :allow-click="true"
        :timeAtCategory="true"
        :loading="loading"
        @select="openNewsDialog"
      />
    </div>
    <NewsDialog :show="showNewDialog" :news="newsDialogEntry" @close="showNewDialog=false"/>
  </div>
</template>

<script>
import moment from 'moment'
import DateList from '@/components/ui/DateList';
import NewsDialog from './NewsDialog';

export default {
  name: 'NewsList',
  components: {
    DateList,
    NewsDialog
  },
  props: {
    news: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newsDialogEntry: null,
      showNewDialog: false,
    }
  },
  computed: {
    today() {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    openNewsDialog(news) {
      this.newsDialogEntry = news
      this.showNewDialog = true
      this.$emit("select", news)
    }
  }
}
</script>

<style scoped>
  .selected {
    background-color: rgba(0, 0, 0, 0.04);
  }
</style>