<template>
  <div class="main-view">

    <div class="map-contols">
      <v-card
        v-if="layer === 'entryrestrictions'"
        class="mt-4"
      >
        <v-container class="pa-6">
          <DestinationWorldMapControl
            :countries-geojson="geoJson"
            :iso2="countryIso2"
            @date-start-selected="dateStart=$event"
            @date-end-selected="dateEnd=$event"
            @country-selected="countrySelected"
          />
          <div class="legend">{{ $t('main.LEGEND_MAP_SOURCE') }}</div>
        </v-container>
      </v-card>

      <v-card class="legend">
        <v-container
          v-if="layer === 'entryrestrictions'"
          class="pa-6"
        >
          <DestinationWorldMapLegend />
        </v-container>
      </v-card>
    </div>

    <v-btn
      v-if="countryIso2"
      class="back-to-earth"
      @click="countryIso2=null"
      color="primary"
    >
      {{ $t('main.BACK_TO_WORLDMAP') }}
    </v-btn>

    <div class="detail-panel">
      <v-card v-if="countryIso2">
        <v-container class="pa-6">

          <DestinationWorldMapCountryDetail
            :iso2="countryIso2"
            :restrictionType="restrictionType"
            :dateStart="dateStart"
            :dateEnd="dateEnd"
          />
        </v-container>
      </v-card>

      <v-card
        class="region-card"
        v-if="regionId"
      >
        <v-container class="pa-6">
          <DestinationWorldMapRegionDetail
            :region-id="regionId"
            :restrictionType="restrictionType"
            :dateStart="dateStart"
            :dateEnd="dateEnd"
          />
        </v-container>
      </v-card>
    </div>

    <DestinationWorldMap
      class="main-map"
      :countries-geojson="geoJson"
      :iso2="countryIso2"
      :dateStart="dateStart"
      :dateEnd="dateEnd"
      :layer="layer"
      :restrictionType="restrictionType"
      @country-selected="countrySelected"
      @region-selected="regionSelected"
    />

  </div>
</template>

<script>
import countriesGeoJson from '../assets/world_30sep2021.json'
import { mapState, mapActions } from "vuex"
import moment from "moment"
import DestinationWorldMap from "@/components/map/DestinationWorldMap"
import DestinationWorldMapControl from "@/components/map/DestinationWorldMapControl"
import DestinationWorldMapCountryDetail from "@/components/map/DestinationWorldMapCountryDetail"
import DestinationWorldMapRegionDetail from "@/components/map/DestinationWorldMapRegionDetail"
import DestinationWorldMapLegend from "@/components/map/DestinationWorldMapLegend"


export default {
  name: 'DestinationMapView',
  components: {
    DestinationWorldMap,
    DestinationWorldMapControl,
    DestinationWorldMapLegend,
    DestinationWorldMapCountryDetail,
    DestinationWorldMapRegionDetail
  },
  data() {
    return {
      layer: 'entryrestrictions',
      countryIso2: null,
      regionId: null,
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().add(2, 'weeks').format("YYYY-MM-DD"),
    }
  },
  computed: {
    ...mapState({
      /* restrictionType is set global for all forms, so we must get it from the store */
      restrictionType: state => state.uiStore.restrictionType,
    }),
    geoJson() {
      return countriesGeoJson
    }
  },
  methods: {
    countrySelected(countryIso2) {
      this.countryIso2 = countryIso2
    },
    regionSelected(regionId) {
      this.regionId = regionId
    }
  }

}
</script>

<style scoped>
  .main-view {
      margin: auto;
  }

  .main-map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .map-contols {
    width: 320px;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1001;
  }

  .legend-panel {
    position: absolute;
    width: 300px;
    bottom: 1rem;
    left: 1rem;
    z-index: 1001;
    font-size: 70%!important;
  }

  .legend {
    margin-top: 1rem;
    z-index: 1001;
    font-size: 70%!important;
    line-height: 0.8rem;
  }

  .detail-panel {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1001;
    font-size: 70%!important;
  }

  .region-card {
    margin-top: 1rem;
  }

  .back-to-earth {
    position: absolute;
    z-index: 1001;
    top: 1rem;
    left: 45%;
  }
</style>
