var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.countriesIsLoading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.countriesIsDone)?_c('div',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.countryByIso2.nameShort))]),(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(!_vm.loading)?_c('div',[_c('DestinationWorldMapStatus',{attrs:{"statusForeignOffice":_vm.countryStatus.statusForeignOffice,"statusDestinationCountry":_vm.countryStatus.statusDestinationCountry,"dateStart":_vm.dateStart,"dateEnd":_vm.dateEnd}}),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","small":"","to":{name: 'destination-result', params: {
          departureId: _vm.germany.id,
          departureType: 'COUNTRY',
          destinationId: _vm.countryByIso2.id,
          destinationType: 'COUNTRY',
          citizenshipCountryId: _vm.germany.id,
          restrictionType: _vm.restrictionType,
          transitId: 0,
          transitType: 'UNDEFINED',
          dateStart: _vm.dateStart,
          dateEnd: _vm.dateEnd,
        }}}},[_vm._v(" "+_vm._s(_vm.$t("main.SHOW_DETAILS"))+" ")])],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }