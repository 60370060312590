<template>
  <div>
    <div v-if="loading">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-if="!loading">
      <h1 class="title">{{ regionStatus.name }}</h1>

      <DestinationWorldMapStatus
        :statusForeignOffice="regionStatus.statusForeignOffice"
        :statusDestinationCountry="regionStatus.statusDestinationCountry"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
      />

      <v-btn
        class="mt-4"
        color="primary"
        small
        :to="{name: 'destination-result', params: {
          departureId: germany.id,
          departureType: 'COUNTRY',          
          destinationId: regionId,
          destinationType: 'REGION',
          citizenshipCountryId: germany.id,          
          restrictionType: restrictionType,
          transitId: 0,
          transitType: 'UNDEFINED',          
          dateStart: dateStart,
          dateEnd: dateEnd,
        }}"
      >
        {{ $t('main.SHOW_DETAILS') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DestinationWorldMapStatus from "./DestinationWorldMapStatus"

export default {
  name: 'DestinationWorldMapRegionDetail',
  components: {
    DestinationWorldMapStatus
  },
  props: {
    regionId: String,
    restrictionType: String,
    dateStart: String,
    dateEnd: String,
  },
  data() {
    return {
      loading: true,
      regionStatus: null
    }
  },
  mounted() {
    if (this.regionId) {
      this.getRegionStatus();
    }
  },
  computed: {
    ...mapState({
      countries: state => state.countriesStore.entries,
    }),  
    germany() {
      let country = this.countries.find(country => country.iso2 == "DE")
      return country
    },    
  },    
  methods: {
    getRegionStatus() {
      this.loading = true
      this.$mapRepository.getRegionStatus(this.regionId, this.restrictionType, this.dateStart, this.dateEnd)
        .then((result) => {
          this.regionStatus = result
          this.loading = false
        })
    },
  },
  watch: {
    regionId() {
      this.getRegionStatus();
    },
  },
}
</script>

<style scoped>
</style>