<template>
  <div>

    <DatePicker
      v-model="dateStart"
      :label="$t('main.TRAVEL_DATE_START')"
    />
    <DatePicker
      v-model="dateEnd"
      :label="$t('main.TRAVEL_DATE_END')"
    />

    <v-select
      v-model="restrictionType"
      :menu-props="{'z-index': 1001}"
      :items="restrictionTypes"
      :label="$t('main.TRAVEL_TYPE')"
    />

    <v-autocomplete
      v-model="iso2Value"
      :items="countries"
      item-text="nameShort"
      item-value="iso2"
      :clearable="true"
      :loading="loadingCountries"
      :label="$t('main.COUNTRY')"
      :placeholder="$t('main.SEARCH_FOR_COUNTRY')"
    />

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import DatePicker from "@/components/ui/DatePicker"
import moment from "moment"

export default {
  name: 'DestinationWorldMapControl',
  components: {
    DatePicker,
  },
  props: [
    'countriesGeojson',
    'iso2'
  ],
  data() {
    return {
      iso2Value: null,
      loadingCountries: true,
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().add(2, 'weeks').format("YYYY-MM-DD"),
      restrictionTypes: [
        {'value': 'TOURISM', 'text': this.$i18n.t('main.PRIVATE_TRAVEL')},
        {'value': 'BUSINESS', 'text': this.$i18n.t('main.BUSINESS_TRAVEL')},
      ],
    }
  },
  mounted() {
    this.doCountriesFetchAll()
  },
  computed: {
    ...mapState({
      /* restrictionType is set global for all forms, so we must get it from the store */
      restrictionType: state => state.uiStore.restrictionType,
      countries: state => state.countriesStore.entries,
    }),
    restrictionType: {
      get() {
        return this.$store.state.uiStore.restrictionType
      },
      set(value) {
        /* restrictionType is set global for all forms, so we must set it in the store */
        return this.$store.commit("uiStore/SET_RESTRICTIONTTYPE", value)
      }
    },
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll",
    }),
    doCountriesFetchAll() {
      this.loadingCountries = true
      this.countriesFetchAll().then(() => {
        this.loadingCountries = false
      })
    }
  },
  watch: {
    dateStart: {
      immediate: true,
      handler(newVal) {
        this.$emit('date-start-selected', this.dateStart)
      },
    },
    dateEnd: {
      immediate: true,
      handler(newVal) {
        this.$emit('date-end-selected', this.dateEnd)
      },
    },
    iso2Value: {
      handler(newVal) {
        this.$emit('country-selected', this.iso2Value)
      },
    },
    iso2: {
      immediate: true,
      handler(newVal) {
        this.iso2Value = newVal
      },
    }
  }
}
</script>